import { projectSectionLinkOffset } from "../pages/projects/sectionLinkOffset";

$(function() {
  $('body').on('click', '[data-bs-target="#main_modal"]', function() {
    var link = $(this);
    var selector = $(this).data('selector');
    var title_selector = $(this).data('title-selector');
    var url = $(this).data('url');
    var video = $(this).data('video');
    var padding = $(this).data('padding');
    var image = $(this).data('image');
    var isProjectPage = document.querySelector('.page-projects-show')
    if(video !== undefined && video.length > 0) padding = false;
    $('#main_modal .modal-body').html("");

    if(padding) {
      $('#main_modal .modal-body').html("<div class='modal-padding'></div>");
      var modal_target = $('#main_modal .modal-body .modal-padding');
    } else {
      var modal_target = $('#main_modal .modal-body');
    }

    var modal_title_target = $('#main_modal .modal-title');

    if(selector && selector.length > 0) {
      modal_target.html($(selector).html());
    }

    if(title_selector && title_selector.length > 0) {
      modal_title_target.html($(title_selector).html());
    } else {
      modal_title_target.html(""); // reset in case previously set
    }

    if(video && video.length > 0) {
      $('#main_modal').on("hide.bs.modal", function(e) {
        $('#main_modal .iframe-responsive-wrapper').html("");
      });
      $('#main_modal .modal-dialog').css({ maxWidth: "90%" });

      modal_target.html(
        '<div class="iframe-responsive-wrapper video-container text-center">' +
        '<iframe frameborder="0" style="width: 80vw; height: 70vh;" src="' + video + '?autoplay=1" allowfullscreen></iframe>"' +
        '</div>'
      );
    }

    if(image === 1) {
      modal_target.html(link.html());
    }

    if(url && url.length > 0) {
      modal_target.load(url);
    }

    if (isProjectPage) {
      projectSectionLinkOffset(150)
    }
  });
});

// overriding bootsrap show modal (not accessible in ujs)
const showModal = (modal_id) => {
  return $(modal_id).modal("show");
}

window.showModal = showModal
