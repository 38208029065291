$('body').on('click', '[data-scroll-to]', function(e) {
  const el = document.querySelector($(this).data("scroll-to"))
  const scrollPosition = el.getBoundingClientRect().top + window.pageYOffset - 10;
  window.scrollTo({top: scrollPosition, behavior: 'smooth'});
  document.querySelector($(this).data("focus-input")).focus()
});

document.addEventListener('DOMContentLoaded', function () {
  const birthCountryField = document.querySelector('.user_account_birth_country select');

  if(birthCountryField) {
    const birthDistrictField = document.querySelector('.user_account_birth_district_number select');
    const birthDistrictContainer = document.querySelector('.birth_district_number_input');

    updateBirthDistrict = (e) => {
      let newDistrictValue = birthDistrictField.value;

      if(birthCountryField.value !== "FR") {
        newDistrictValue = "99";
        birthDistrictContainer.classList.add("d-none");
      } else {
        if(birthDistrictField.value === "99") {
          newDistrictValue = "";
        }
        birthDistrictContainer.classList.remove("d-none");
      }
      birthDistrictField.value = newDistrictValue;
    }
    birthCountryField.addEventListener('change', updateBirthDistrict);
    updateBirthDistrict();
  }
});
