$(document).ready(function() {
  $("#show-more-button").click(function() {
    $("#sidebar-body").animate({
      scrollTop: $("#sidebar-body")[0].scrollHeight
    }, 500);
  });

  function isElementVisible(el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  $("#sidebar-body").scroll(function() {
    var $lastSectionItem = $(".arkefip-app-sidebar-navigation-section-item:last");
    var $showMoreButton = $("#show-more-button");

    if (isElementVisible($lastSectionItem[0])) {
      $showMoreButton.hide();
    } else {
      $showMoreButton.show();
    }
  });
});